<template>
  <div>
    <el-button type='primary' @click="goFun">跳转到资金方大屏</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {

    this.goFun()
    // this.$router.push('/leaseholdMgmt/fundingPartyScreen')
  },
  methods: {
    goFun() {
        const { origin} = location
        const url = `${origin}/leaseholdMgmt/fundingPartyScreen`
        window.open(url)
    }
  },
  components: {

  }
}
</script>

<style scoped>

</style>
